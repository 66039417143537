html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
}
.footer {display:none;}
.App {
  text-align: center;
  height: 100%;
  background-color: #F5F5F5;
  width:100%;
  overflow: auto;
  position: absolute;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #f5f5f5!important;
    background-image: none!important;
  }
}
.App-header {
    background-image: linear-gradient(to bottom right,#8acb88,#648381);
    color: #fff;
    font-size: calc(10px + 2vmin);
    min-height: 100vh;
    width: 60px;
    z-index: 100;
}

.App-link {
  color: #61dafb;
}

.icon-container-main {
    left: 10px;
    position: absolute;
    top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.feedbacks-page {
  margin-bottom:150px;
}
.adfeedback-btn-section{
  text-align: center;
}

.logout-link {
  color: gray;
  font-size: 16px;
  cursor: pointer;
}

.adfeedback-btns {
  width: 20%;
  height: 50px;
  background-color: #fefefe;
  color: #8acb88;
  border-color: #8acb88;
  font-weight: bold;
  border-width: medium;
  border-radius: 10px;
}

  .adfeedback-btns:hover {
      background-color: #8acb88;
      border-color: #8acb88;
  }

.overlay-feedback-btn-arrows-wrapper .overlay-feedback-btn-arrow{display:none;}
.carousel-inner {
  height: 900px;
}

.root-container-spacing {
  padding-top: 0;
}

.ad-wrapper-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding-top:15px;
  margin: 1vw;
}

.ad-wrapper {
  width: fit-content;
  height: 100%;
  position: relative;
}

.ad-main-container {
  overflow: scroll;
  height: 100%;
  width: 600px;
}

.ad-page-sub-banner {
  height: 40px;
  background-color: #e9e9e9;
  color: #999;
  font-size: 20px;
  font-weight: 400;
  padding-top:5px;
  display: none;
}

.ad-page-sub-banner-bottom {
  height: 40px;
  background-color: #e9e9e9;
  color: #999;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  padding-top:5px;
  position: fixed;
  bottom: 0;
  display: none;
}

.ad-page-sub-banner a, .ad-page-sub-banner-bottom a:visited, .ad-page-sub-banner-bottom a:active {
    color: #333!important;
}

.ad-container-inner{
  /*margin-top: -50px;*/
}

.ad-tile-overlay {
  -webkit-transition: max-height 4s;
  -moz-transition: max-height 4s;
  -ms-transition: max-height 4s;
  -o-transition: max-height 4s;
  transition: max-height 4s;
  position: absolute;
  bottom: 0px;
  font-size: 18px;
  background-color: rgba(0,0,0,0.7);
  width: 600px;
  height: max-content;
  border-radius: 15px;
  color: white;
  z-index: 99;
}

.ad-tile-overlay .content a, .ad-tile-overlay .content a:visited, .ad-tile-overlay .content a:active {
  padding: 10px;
  width: 100px;
  display: block;
  margin: auto;
  color: #cfcfcf;
  background: #343434;
  border: 1px #c0c0c0 solid;
  border-radius: 15px;
  margin-top: 15px;
}

.ad-tile-overlay-line {
  width: 16%;
  color: white;
  background: white;
  height: 7px;
  border-radius: 3px;
  margin-left: 42%;
  margin-top: 10px;
  cursor: pointer;
}

.ad-tile-overlay-extended{
  height: 350px;
}

.ad-tile-overlay-full {
  height: 815px;
}

.actively-shopping-indicator-text {
  color: #ffbf46 !important;
  font-weight: bold;
  font-size: 22px;
}

.ad-does-not-apply-wrapper {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  position: absolute;
  width: 50%;
  left: 25%;
  bottom: 35px;
  background: none;
  border: none;
  color: white;
}

.ad-preferred-brand-icon {
  color: #ffbf46 !important
}

.ad-favorite-brand-icon {
  color: red !important
}

.review-feedback-txt-area{
  height: 100%;
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  line-height: 20px;
}

.no-ads-container {
  top: 15vw;
  width: fit-content;
  color: gray;
  font-size: 35px;
  position: relative;
}

.no-ads-text-container {
  width: max-content;
  padding-bottom: 30px;
}

.overlay-feedback-middle {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  border: none;
  background-color: #8acb88;
  color: white;
  margin-right: 30px;
  margin-left: 30px;
  position: relative;
  top: -10px; 
  cursor: pointer;
  font-size: 18px;
}

.overlay-expanded-text-business-name {
  font-size: 22px;
  font-weight: bold;
  padding: 8px;
}

.overlay-expanded-text-dollars {
  font-weight: bold;
  padding: 8px;
  color: #889b73;
  font-size: 22px;
}

#root {
}

.banner-container {
  height: 100px;
  background-color: rgba(4, 4, 4, 0.4);
  position: relative;
  width:100%;
  border-bottom: 1px solid #999999;
}

.non-home-page-banner-container {
  background-image: url(https://img.freepik.com/premium-vector/white-abstract-background-with-hexagon-pattern-style-seamless-concept_7505-2041.jpg);
  height: 80px;
  background-color: rgba(4, 4, 4, 0.4);
}

.non-home-page-banner-container::before {
  background-color: rgba(0,0,0,.75);
  bottom: 0;
  content: "";
  height: 80px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.sml-banner-notification-alert {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 5px;
  display: inline-block;
  top: 5px;
  right: 0px;
}

.banner-welcome-text {
  color: #fff;
  font-size: xx-large;
  font-weight: 600;
  padding-left: 120px;
  padding-top: 35px;
  position: relative;
  text-align: left;
  z-index: 3;
}

.overlay-feedback-btn {
  height: 80px;
  border-radius: 20px;
  width: 150px;
  background-color: #575761;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 15px;
  position: relative;
  top:-15px;
}

.overlay-feedback-btn-interested {
  background-color: #ffbf46;
  color: white;
  border: none;
  height: 80px;
  border-radius: 20px;
  width: 150px;
  cursor: pointer;
  font-size: 15px;
  position: relative;
  top:-15px;

}

.overlay-feedback-btn-arrow {
  position: relative;
  top: -12px;
  left: -25px;
  border-radius: 20px;
  border: none;
  font-size: 40px;
  background: transparent;
  color: #ffffff54;
  cursor: pointer;
  font-size: 40px;
  width: 29px;
  height: 29px;
}
.overlay-feedback-btn-arrow-right{
  right:-20px;
  left: unset;
}
.overlay-feedback-btn-arrow-icon {
  position: relative;
  top: -10px;
  left: -6px;
}

.overlay-feedback-btn-row {
  margin-bottom: 50px;
  margin-top: 20px;
}

.ad-image-active {

}

.ad-image-inactive {
  display: none;
}

.overlay-content-container {
  color: white;
  text-align: center;
  padding-top: 10px;
  display: none;
}

.overlay-content-business-name {
  font-size: xx-large;
  font-weight: bold;
}

.overlay-content-business-category {
  font-size: larger;
}

.overlay-content-business-price {
  color: #889b73;
  font-weight: bold;
  font-size: x-large;
}

.business-line-item-img {
  float: left;
  width: 20%;
  margin-right: 12px;
}

.list-item-business-category {
  color: grey;
  font-size: 20px;
}

.list-item-business-name {
  font-size: 25px;
}

.list-item-business-feedback-icon {
  float: right;
  font-size: 30px;
  cursor: pointer;
}

.list-item-business-money-sign {
  font-size: 16px;
}
.add-business-btn-wrapper {
  width: 70%;
  margin: auto;
  position: relative;
}
.add-business-btn-wrapper span {
  left: 70%;
  position: absolute;
  top: -40px;
  right: 0px;
  margin-left: 142px;
  width: 60px;
  font-size: 12px;
}

.add-brand-btn {
  border: none;
  border-radius: 30px;
  font-size: 25px;
  left: 70%;
  width: 45px;
  position: absolute;
  top: -90px;
  margin-left: 150px;
  height: 45px;
  background-color: #8acb88;
  border: none;
  color: #fff;
  cursor: pointer;
}

.no-ads-container .add-business-btn-wrapper {
  margin: auto;
  position: relative;
  width: 200px;
  height: 70px;
}
.no-ads-container .add-business-btn-wrapper span {
  font-size: 12px;
  left: 37%;
  position: absolute;
  right: 0;
  top: 50px;
  margin:0px;
  width: 60px;
}

.no-ads-container .add-brand-btn {
  background-color: #8acb88;
  border: none;
  border-radius: 30px;
  color: #fff;
  text-align:center;
  padding:0px;
  cursor: pointer;
  font-size: 25px;
  height: 45px;
  left: 40%;
  top:0px;
  margin:0px;
  position: absolute;
  width: 45px;
}

.add-social-connection-wrapper{
  text-align: left;
  padding: 20px;
}

.add-social-connection-btn {
  border: none !important;
  border-radius: 30px !important;
  color: #fff !important;
  cursor: pointer !important;
  font-size: 25px !important;
  height: 60px !important;
  width: 60px !important;
  display: inline-block !important;
  background-color: #8acb88 !important;
  margin-right: 15px !important;
}

.invite-friends-social-btn-label{
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
}

.add-new-business-modal-search {
  margin-top: 15px;
  position: relative;
  height: 85px;
}

.add-new-business-modal-result select {
  border: 1px solid #d3d3d3;
  border-radius: 25px;
  color: #999;
  font-size: 18px;
  height: 50px;
  padding: 15px;
  width: 100%;
  max-width: 225px;
}

.add-new-business-modal-result {
  margin: '100px auto auto auto';
}

.add-new-business-modal-btn-wrapper {
  text-align: center;
  position: fixed;
  bottom: 30px;
  left: calc(50% - 110px);
}

.add-new-business-label {
  font-weight: bold;
}

.add-new-business-result-logo {
  border-radius: 80px;
}

.payment-sum-header {
  text-align: center;
  margin-top: 30px;
  background-color: #FFBF46;
  height: 100px;
}

.payment-line-item {
  border: solid lightgray 1px;
}

.payment-line-item-text {
  padding: 10px;
  font-size: 18px;
  display: inline-block;
}

.payment-line-item-money {
  display: inline-block;
  color: green;
  font-size: 25px;
  font-weight: bold;
  padding: 15px;
  width: 135px;
}

.payment-line-item-money-withdraw {
  display: inline-block;
  color: red;
  font-size: 25px;
  font-weight: bold;
  padding: 15px;
  width: 135px;
}

.payment-line-item-money-pending {
  display: inline-block;
  color: grey;
  font-size: 25px;
  font-weight: bold;
  padding: 15px;
  width: 135px;
}

.send-to-bank-btn-container {
  text-align: center;
  padding: 25px;
}

.payment-item-chevron {
  margin-left: 80%;
  cursor: pointer;
}

html {
  font-size: 14px;
  height: 100%;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

html {
  position: relative;
  min-height: 100%;
}

body {
    background-color: #f5f5f5;
    max-width: 100% !important;
    height: 100%;
}

header {
    background-color: white;
    position: fixed;
    height: 100%;
}

.side-nav-content{
}

.profile-popup-container {
  background: #fff;
  height: 500px;
  position: absolute;
  width: 325px;
  z-index: 99;
  bottom: -15px;
  left: 50px;
}

.profile-popup-container-top {
  height: 80%;
}

.profile-popup-container-bottom {
  height: 20%;
  background-color: #ffbf46;
}

.profile-popup-container-signout-link {
  font-size: 18px;
  color: gray !important;
}

.profile-popup-container-dollar{
  display: inline-block;
  width: 70%;
  height: 100%;
}

.profile-popup-container-credit-content-wrapper{
  display: inline-block;
  width: 30%;
  height: 100%;
  background-color: rgba(255, 191, 70, 0.3);
}

.profile-popup-credit-card-label {
  font-size: 18px;
}

.profile-popup-icon-label {
  color: gray;
  font-size: 20px;
}

.profile-popup-container-close-icon {
  color: lightgray;
  font-size: 30px;
  text-align: right;
  padding-right: 12px;
}

.profile-popup-container-profile-icon {
  color: black;
  font-size: 75px;
}

.profile-popup-link-icon-container {
  background-color: gray;
  border-radius: 45px;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  height: 60px;
  width: 60px;
  padding-top: 10px;
  margin-bottom:50px;
}

.profile-popup-link-icon-container .svg-inline--fa {

}
.profile-content-inner {
  display:inline-block;
  width:80%;
  margin:auto;
}

.profile-label {
    color: gray;
}

.profile-user-name {
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
}

.profile-gender-age{

}

.profile-user-interests {
    padding-bottom: 30px;
}

.profile-container {
    border: black 1px solid;
    margin-top: 35px;
    margin-bottom: 20px;
}

.profile-icon {
    background-color: lightgray;
    width: 69px;
    height: 69px;
    border: white 1px solid;
    border-radius: 50px;
    float: left;
    padding-top: 20px;
    padding-left: 20px;
    margin-top: 25px;
    margin-right: 15px;
}

.profile-container-details {
    display: inline-block;
}

.profile-inner-container{
    padding: 20px;
}

.carousel-img {
    width: 100%;
    height: 330px;
}

.ad-carousel-content {
    display: block;
    color: black;
    padding: 20px;
    border: black 1px solid;
}

.ad-carousel-content-header {
    color: gray;
}

.ad-carousel-content-business {
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
}

.ad-carousel-content-prompt{

}

.feedback-btn {
    margin-left: 37%;
    width: 25%;
    height: 50px;
    background-color: #fefefe;
    color: #8acb88;
    border-color: #8acb88;
    font-weight: bold;
    border-width: medium;
    border-radius: 10px;
}
#share-ad-modal {
    position: relative!important;
    inset: unset!important;
    border: 1px solid rgb(204, 204, 204);
    background: rgba(0, 0, 0, 0.5);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 500px!important;
    text-align: center;
    height: 500px!important;
    margin: auto!important;
    margin-top: 135px!important;

}
#share-ad-modal .form-label {
  color:#999999!important;
}
#share-ad-modal .form-section input {
  width:95%!important;
}
#is-interested-modal {
    inset: 55% auto auto 40%!important;
    width: 35%!important;
    max-width: 400px!important;
}
#not-interested-modal {
  inset: 55% auto auto 60%!important;
  width: 35%!important;
  max-width: 400px!important;
}
#provide-feedback-modal {
  position: relative!important;
  width: 500px!important;
  margin: auto!important;
  margin-top: 135px!important;
  inset: unset!important;
  overflow: unset!important;
}
#provide-feedback-modal .form-section-label {
  color:#cccccc!important;
}
#provide-feedback-modal .form-control {
  height:325px;
  left:20px;
}
#provide-feedback-modal .form-section {
  height:325px;
}
    .feedback-btn:hover {
        background-color: #8acb88;
        border-color: #8acb88;
    }

.carousel {
    width: fit-content;
    margin: 100px auto;
    background: white;
    border-radius: 14px;
}

.text-primary {
    color: #8acb88 !important;
}

a:visited{
  color: white !important;
}

.ad-page-sub-banner-bottom a, .ad-page-sub-banner-bottom a:visted {
  color:#333!important;
}

.text-alt-color {
    color: #575761;
}

.btn-login{
    background-color: #575761;
    border-color: #575761;
}

    .btn-login:hover {
        background-color: #8acb88;
        border-color: #8acb88;
    }

.consumer-summary-total-amount {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    color: green;
    margin-top: 25px;
}

.consumer-summary-count-left {
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    width: 50%;
    color: orange;
    margin-top: 50px;
    display: inline-block;
}

.consumer-summary-count-right {
    display: inline-block;
    width: 50%;
    text-align: center;
    font-size: xx-large;
    font-weight: bold;
    color: orange;
    margin-bottom: 50px;
}

.consumer-summary-label {
    color: black;
    font-weight: normal;
    font-size: large;
}

.consumer-summary-divider {
    width: 26%;
    margin-left: 37%;
    color: lightgrey;
    background-color: lightgrey;
    height: 3px !important;
}

.icon-container {
  background-color: rgba(4, 4, 4, 0.4);
  width: 40px;
  border-radius: 25px;
  height: 40px;
  margin-top: 30px;
  color: white !important;
  position: relative;
}

.icon-container-active {
  background-color: rgba(4, 4, 4, 0.8);
  width: 40px;
  border-radius: 25px;
  height: 40px;
  margin-top: 30px;
  color: white !important;
  position: relative;
}
.App-header .icon-container img {
  padding:0px;
  top:10px;
  left:10px;
  position: absolute;
}

.icon-container-large {
  background-color: rgba(4, 4, 4, 0.4);
  width: 100px;
  border-radius: 50px;
  height: 100px;
  margin-top: 30px;
}

.icon-container-svg {
  padding-top: 5px;
  width:20px;
}

.icon-container-svg-main-icon{
  padding-top: 5px;
}

.icon-container-svg .svg-inline--fa {
    height:20px;
    vertical-align:0px;
}

.large-icon {
  height: 80px;
  padding-top: 10px;
  width: 40px;
}

.home-summary-bubble {
  position: absolute;
  width: 120px;
  height: 120px;
  background-image: linear-gradient(to bottom right, #8acb88, #648381);
  border-radius: 75px;
  top: 25px;
  color: white;
  cursor: pointer;
  z-index: 99;
}

.home-summary-bubble div {font-size: 12px;}

.non-home-summary-bubble {
  display: none;
  cursor: pointer;
  height: 50px;
  right: 5%;
  top: 55px;
  width: 50px;
  position: absolute;
  background-image: linear-gradient(to bottom right, #8acb88, #648381);
  border-radius: 25px;
  color: white;
  z-index:99;
}

.home-summary-bubble-first {right:350px;}
.home-summary-bubble-second {right:200px;}
.home-summary-bubble-third {right:50px;}

.home-summary-bubble-content {
  padding-top: 20px;
  font-size: 42px!important;
  font-weight: bold;
}

.non-home-summary-bubble-content {
  padding-top: 10px;
  font-weight: bold;
  font-size: 20px;
}

.home-page-summary-section {
  display: inline-block;
  background-color: white;
}

.home-page-summary-section-label {

}

.total-responses-count {
  font-size: 40px;
  font-weight: 900;
  color: #ffbf46;
  padding: 10px;
}

.positive-feedback-percentage {
  padding-left: 10px;
}

.home-page-summary-section-label-first {
  
}

.gold {
  color: #FFBF46;
}

.white-text {
  color: white;
}

.profile-content-main-area-wrapper button {
  background-color: #ffbf46;
  border: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  height: 60px;
  width: 225px;
}

.profile-button {
  background-color: #ffbf46;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: large;
  height: 30px;
  width: 40px;
}

.profile-button-container {
  position: absolute;
  left: 10px;
  bottom: 15px;
}

.profile-button .svg-inline--fa {
    height: 15px;
    vertical-align: -2px;
}

.home-content-wrapper {
  height: 400px;
  overflow: hidden;
  width: 90%;
  position: relative;
  left: 8%;
}

.home-content-bottom {
  position: relative;
  bottom: -290px;
}

.home-page-summary-percentage {
  font-size: 40px;
  font-weight: 900;
  color: #8acb88!important;
}

.home-page-summary-section-label {
  font-size: 14px;
  clear: both;
  width: 100%;
  display: block;
  color: #ffffff!important;
}

.home-content-title {
  color: white;
  font-size: 60px;
  font-weight: bold;
  left: 90px;
  position: absolute;
  width: max-content;
  top: 90px;
}

.standard-btn {
  width: 200px;
  height: 60px;
  background-color: #FFBF46;
  border: none;
  border-radius: 15px;
  color: white;
  font-size: large;
  cursor: pointer;
}

.home-content-body {
  color: #ffbf46;
  font-weight: 400;
  font-size: 28px;
  position: absolute;
  top:165px;
  left:90px;
  max-width: 1200px;
  text-align: left;
}
.home-page-summary-wrapper {
  width: 90%;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.home-page-summary-section {
  text-align: left;
  padding: 35px;
  background-color: #0000007a;
  color: #fff;
  margin: 1%;
  border-radius: 25px;
  width:20%;
}
.home-page-summary-section div {
  padding-left:10px;
  margin:10px;
}
.home-page-summary-section-label {
  font-weight: 700;
}

.home-page {
  background-image: url(https://img.freepik.com/premium-vector/white-abstract-background-with-hexagon-pattern-style-seamless-concept_7505-2041.jpg);
  height: 100%;
  background-position-y: bottom;
  overflow: auto;
  width:100%;
}

.home-page::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%);
}

.ads-page {
  height: 100%;
}

.social-page {
  height: 100%;
  background-image: linear-gradient(to right, #8acb88, #648381);
  color: white;
  overflow: auto;
  padding: 20px;
}

.social-spinner-container {
}

.integration-social-link{
  cursor: pointer;
}

.social-page-top {
  text-align: center;
  padding-top: 3vw;
}

.social-page-top-icon-container {
  display: inline-block;
  padding: 12px;
  font-size: xxx-large;
}

.social-page-top-text {
  display: inline-block;
  font-size: xxx-large;
}

.inline-element {
  display: inline-block;
}

.text-strike {
  text-decoration: line-through;
}

.replacement-text {
  position: relative;
  left: 17%;
}

.spaced-text {
  margin-right: 7px;
}

.social-icon-bubble {
  width: 75px;
  height: 75px;
  border-radius: 45px;
}

.social-page-content {
  padding-top: 20px;
  padding-bottom: 60px;
}

.social-page-social-container {
  display: inline-block;
  padding: 100px;
  padding-bottom: 0;
  padding-top: 50px;
}

.social-page-social-container-inner {
  margin-left: 10%;
}

.social-selection {
  cursor: pointer;
  position: relative;
  left: 15%;
}

.social-continue-btn {
  width: 200px;
  height: 60px;
  background-color: #FFBF46;
  border: none;
  border-radius: 15px;
  color: white;
  font-size: large;
  cursor: pointer;
  margin: 15px;
}

.profile-registration-btn{
  width: 200px;
  height: 60px;
  background-color: #FFBF46;
  border: none;
  border-radius: 15px;
  color: white;
  font-size: large;
  cursor: pointer;
  position: relative;
}

.profile-registration-btn-wrapper{
  margin-top: 40px;
}

.social-page-description-container{
  width: 80%;
  text-align: center;
  position: relative;
  left: 10%;
}

.home-content-bottom .social-continue-btn {
    background-color: #ffbf46;
    border: none;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    left: 90px;
    height: 60px;
    width: 200px;
    position: absolute;
}

.InputField {
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: white;
  font-size: 21px;
  padding: 10px;
  padding-left: 20px;
  height: 50px;
}

.InputField:hover {
  cursor: pointer;
}

.InputFieldChecked {
  background-color: rgba(247, 247, 247, 0.3)!important;
}

.not-interested-modal-not-interested-btn {
  width: 100%;
  height: 70px;
  border: none;
  font-size: 25px;
  text-align: left;
  cursor: pointer;
  background: #648381;
  color: white;
  border: white 1px solid;
  border-radius: 10px;
  padding-left:15px;
}

.not-interested-modal-not-interested-btn:hover{
  background: #79797e;
  border: #F5F5F5 1px solid;
  border-radius: 7px;
}

.interested-modal-interested-btn {
  width: 100%;
  height: 70px;
  border: none;
  font-size: 25px;
  text-align: left;
  cursor: pointer;
  background: #648381;
  color: white;
  border: white 1px solid;
  border-radius: 10px;
  padding-left:15px;
}

.interested-modal-interested-btn:hover {
  background: #f5c56c;
  border: #F5F5F5 1px solid;
  border-radius: 7px;
}

.feedback-modal-btn-icon {
  font-size: xx-large;
  padding-right: 15px;
}

.feedback-modal-checkbox {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 92%;
  left: 16px;
  height: 57px;
  top: 32px;
}

.feedback-modal-checkbox:hover{
  background-color: #575761;
}

.feedback-modal-checkbox:checked{
  background-color: #575761;
}

.registration-content {
  height: 100%;
  background-image: linear-gradient(to right, #8acb88, #648381);
  color: white;
  overflow: auto;
}

.registration-content-inner{
  position: relative;
  top: 3%;
  padding-bottom: 3%;
}

.registration-content-header-logo-container {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 55px;
}

.registration-content-header-logo{
  padding: 8px;
}

.registration-content-header-text {
  display: inline-block;
  position: relative;
  font-size: 40px;
  font-weight: 600;
  left: 25px;
  bottom: 25px;
  color: #FFBF46;
}

.registration-content-header {
  padding: 25px;
}

.data-sync-subtext-wrapper {
  width: 80%;
  position: relative;
  left: 10%;
}

.data-sync-save-btn-wrapper{
  position: relative;
  left: calc(50% - 100px);
  top: 25px;
}

.collapsible .content {
  padding: 6px;
}
.collapsible .header {
  background-color: #dddddd;
  padding: 2px;
  cursor: pointer;
}

.feedback-review-table-contaier {
    background-color: #f5f5f5;
    color: gray;
    font-size: 18px;
    font-weight: 700;
    overflow: auto;
    position: relative;
    width: 70%;
    top: 75px;
    margin: auto;
}

.feedback-review-table {
  width: 100%;
}

.feedback-review-table td {
  max-width:175px;
  overflow: hidden;
}

.feedback-review-table-header {
    color: gray;
    font-size: 21px;
    font-weight: 700;
    position: relative;
    width: 70%;
    margin: auto;
    padding-top: 60px;
}

.feedback-review-table-header span {
    position: absolute;
    left: 25px;
    font-size: 24px;
    padding-top:10px;
}

.feedback-table-row-interested {
  background-color: #ececec;
}

.feedback-review-brand-filter-label {
  display: inline-block;
  padding-top: 35px;
  cursor: pointer;
}

.feedback-review-brand-filter-label:hover {
  color:red;
}

.feedback-review-brand-filter-label-name {
  font-size: 15px !important;
}

.first-cell-interested {
  background-color: #FFBF46;
  width: 7px;
}

.first-cell-not-interested {
  background-color: #5f5f5e;
  width: 7px;
}

tr:nth-child(odd) {
  background-color: #FEFFFE;
}

table { 
  border-collapse:collapse;
  width: 100%;
 }

tr {height: 75px;}

.feedback-review-table tr {
  height:100px;
}

.feedback-edit-btn {
  width: 75px;
  height: 83px;
  border: none;
  background-color: gray;
  color: white;
  cursor: pointer;
  float: right;
}

.ad-feedback-business-img-container {
  padding: 20px;
  position: relative;
  left: -10px;
}

.ad-feedback-business-img {
  width: 60px;
  border-radius: 50px;
}

.ad-feedback-business-category {
  font-size: 12px;
  font-weight: 500;
}

.send-feedback-modal-btn {
  margin-top: 5px;
  width: 230px;
  height: 60px;
  background-color: #8acb88;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  font-size: 20px;
}

.edit-feedback-modal-banner {
  background-color: #FFBF46;
  position: relative;
  height: 40px;
  top: -20px;
  width: calc(100% + 40px);
  left: -20px;
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
}

.edit-feedback-modal-banner-close {
  float: right;
  padding-right: 15px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  margin:auto;
  margin-top: 250px;
  padding-bottom: 309px;
  width:50px;
  position: relative;
}

.category-section-wrapper {
  position: relative;
  width: 70%;
  margin: auto;
}

.category-section-name {
  color: gray;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 20px;
  position: relative;
  text-align: left;
}

.category-section-tile-wrapper {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 25px 0 0 25px;
  display: inline-block;
  height: 125px;
  margin-bottom: 25px;
  margin-right: 50px;
  width: 375px;
  position: relative;
}

.category-section-tile-img-wrapper {
  position: absolute;
  left: 30px;
  top: 35px;
}

.category-section-tile-img {
  border-radius: 30px;
  width: 50px;
  height: 50px;
}

.cateogry-section-tile-content-wrapper {
  display: inline-block;
  height: 100%;
  margin-left: 100px;
  overflow: hidden;
  text-align: left;
  width: 175px;
}

.category-section-tile-business-name {
  color: gray;
  font-size: 21px;
  font-weight: 600;
  padding-top: 20px;
}

.category-wrapper {
  background-color: #f5f5f5;
  padding-left: 25px;
  margin-bottom:50px;
}

.category-section-tile-view-btn-wrapper {
  height: 125px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.category-section-tile-view-btn {
  height: 100%;
  border: none;
  width: 75px;
  cursor: pointer;
  background-color: #868383;
  color: white;
  font-size: 17px;
}

.category-section-businesses {
  text-align: left;
}

.chevron-down-category-wrapper {
  display: inline-block;
  padding-right: 35px;
  font-size: 40px;
  cursor: pointer;
}
.cat-search-btn {
  background-color: #8acb88;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  left: 70%;
  height: 60px;
  position: absolute;
  border-radius: 0px 25px 25px 0px;
  width: 125px;
}

.bus-search-btn {
  background-color: #8acb88;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  left: 75%;
  text-align: center;
  height: 60px;
  position: absolute;
  border-radius: 0px 10px 10px 0px;
  width: 25%;
}

.business-category-name {
  display: block;
  position: absolute;
  font-size: 22px;
  top: 12px;
  left: 60px;
}

.business-category-search-wrapper {
  padding-top: 60px;
  padding-bottom: 90px;
  position: relative;
  margin: auto;
  width: 70%;
}

.business-category-search-bar {
  font-size: 18px;
  height: 60px;
  width: 70%;
  padding-left: 15px;
  position: absolute;
  left: 0px;
  border: 1px solid #d3d3d3;
  border-radius: 25px 0px 0px 25px;
}

.qr-reader-wrapper {
  position: absolute;
  height: 385px;
  z-index: 99;
  right: 0;
  width: 100%;
}

.qr-reader .qr-frame {
  margin: 10% 0 0 40%!important;
}

.qr-reader-btn {
  background-color: #8acb88;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  top: 0;
  left: 89%;
  height: 60px;
  position: absolute;
  border-radius: 35px;
  width: 60px;
}

.business-modal-search-bar {
  font-size: 18px;
  height: 60px;
  width: 75%;
  padding-left: 15px;
  position: absolute;
  border: 1px solid #d3d3d3;
  border-radius: 10px 0px 0px 10px;
}

.add-brand-ad-modal-type-selector{
  width: 100%;
  background-color: #F5F5F5;
  border-radius: 10px;
  height: 50px;
  vertical-align: middle;
  line-height: 36px;
}

.add-brand-ad-modal-type-selector-option-wrapper{
  width: 50%;
  display: inline-block;
  text-align: center;
  padding: 10px;
  font-weight: 500;
  color: #648381;
  padding-top: 7px;
  padding-bottom: 7px;
}

.add-brand-ad-modal-type-selector-option{
  border-radius: 10px;
}

.add-brand-ad-modal-type-selector-option-active{

}

.ReactModal__Overlay {
  z-index: 99;
}
.add-new-business-modal-result {
  display: block;
  position: relative;
  padding: 10px;
  padding-top: 25px;
}

.add-new-business-modal-result-img-wrapper {
  display: inline-block;
  width: 100px;
  position: absolute;
  margin-right: 10px;
}

.add-new-business-modal-result-details-wrapper {
  display: inline-block;
  width: calc(100% - 110px);
  position: relative;
  left: 120px;
}

#search-brand-modal .social-continue-btn {
  position: relative;
  left:0px;
  top: 20px;
}
#search-brand-modal .business-modal-search-bar {font-size:14px;}
.edit-feedback-modal-btn {
  width: 150px;
  height: 55px;
  border: none;
  font-size: 25px;
  cursor: pointer;
  background: rgb(63, 63, 63);
  color: white;
  position: relative;
  border-radius: 10px;
  margin-left: 39%;
}

.carousel-status {display:none;}

.carousel-root {
  height: 100%;
}

.carousel.carousel-slider {
  height: 100%;
}

.carousel .slider-wrapper {
  height: 100%;
}

.carousel {
  background: none;
}

.carousel.carousel-slider .control-arrow {
  top: 50% !important;
  color: #fff;
  font-size: 26px;
  bottom: 0px;
  margin-top: 0;
  padding: 5px;
  height: 75px;
  width: 75px;
  border-radius: 50px;
}

.feedback-form-text-area {
  width: 90%;
  resize: none;
  height: 250px;
  border: none;
  border-radius: 20px;
  font-size: 18px;
  margin-top: 30px;
  padding:10px;
}

.feedback-load-more-wrapper{
  padding: 20px;
  font-size: 22px;
  text-decoration: underline;
}

.profile-page-wrapper {
  height: fit-content;
}

.profile-section {
  height: 90%;
}

.profile-likes-wrapper{
  
}

.copy-link-btn {
  margin-left: 10px;
  width: 50px;
  height: 25px;
  background-color: #FFBF46;
  border: none;
  border-radius: 20px;
  color: white;
  font-size: large;
  cursor: pointer;
}

.logout-btn-wrapper {
  position: relative;
  padding: 25px;
  padding-bottom: 125px;
}

.logout-btn {
  background-color: #ffbf46;
  border: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  width: 225px;
  text-align: center;
  border-radius: 25px;
  height: 60px;
}


.Toastify__progress-bar-theme--light{
  background: #8acb88 !important;
}

.profile-content-wrapper {
  background-color: #fff;
  height: 100%;
  margin-left: 75px;
  position: relative;
  width: 70%;
  margin-top: 15px;
  max-width: 900px;
}
.profile-section .btn-primary {
  text-align: center;
  border-radius: 25px;
  height:60px;
}
.profile-section .feedback-modal-btn-icon {
  padding:0px;
  display: none;
}
.profile-content-btn-row {
  width: 95%;
  background-color: #F5F5F5;
  position: relative;
  margin:auto;
  margin-top:40px;
  height: 50px;
  border-radius: 10px;

}

.profile-content-btn-row-btn-container {
  display: inline-block;
  width: 33.33%;
  height: 100%;
  vertical-align: middle;
}

.profile-content-btn-row-btn-container-btn-active {
  background: #fff;
  border: 1px solid #ffbf46;
  border-radius: 6px;
  color: #ffbf46;
  cursor: pointer;
  font-weight: 700;
  height: 80%;
  margin-top: 5px;
  font-size: 12px;
  width: 95%;
}

.profile-content-btn-row-btn-container-btn {
  background: #f5f5f5;
  border: none;
  border-radius: 6px;
  color: gray;
  cursor: pointer;
  font-weight: 700;
  height: 70%;
  font-size: 12px;
  margin-top: 8px;
  width: 95%;
}

.activity-stream {
  list-style: none;
  padding: 0;
}

.activity-stream li {
  background-color: #ffffff;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.timestamp {
  color: #888;
  font-size: 12px;
}

.profile-content-top{
  font-size: 24px;
  width: 90%;
  margin-left: 5%;
  height: 175px;
  padding-top: 25px;
  text-align: left;
}

.profile-content-top-img {
  font-size: 100px;
  color: gray;
  display: inline-block;
  position: relative;
}

.profile-content-top-content {
  display: inline-block;
  padding-left: 28px;
  width:75%;
}

.profile-content-top-content-link {
  font-size: 14px;
  color:#999;
}

.profile-cotent-top-content-name {
  font-size: 32px;
  font-weight: bold;
}

.profile-content-main-wrapper {
  width: 100%;
  background-color: #F5F5F5;
  position: relative;
  margin: auto;
  margin-top: 10px;
  height: 80%;

}

.profile-content-main-wrapper select {
  border: 1px solid #d3d3d3;
  border-radius: 25px;
  color: #999;
  font-size: 18px;
  height: 50px;
  padding: 15px;
  width: 225px;
}

.profile-content-main-nav-btn-area-wrapper {
  width: 35%;
  height: 100%;
  display: inline-block;
  height:500px;
  position:absolute;
  left:0;
  top:0;
}

.profile-content-main-area-wrapper {
    background-color: #f9f9f9;
    color: grey;
    display: inline-block;
    height: 100%;
    padding-bottom: 15px;
    margin-left: 35%;
    overflow:hidden;
    min-height: 600px;
    position: relative;
    vertical-align: bottom;
    width: 65%;
}
.profile-content-main-area-wrapper form {
  padding-top:15px;
}

.profile-content-main-nav-btn-wrapper {
  height: 14.25%;
}

.profile-content-main-nav-btn {
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: grey;
}

.profile-content-main-nav-btn-active {
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: white;
  background-color: #ffbf46;
}

.profile-content-main-content-row {
  text-align: left;
  display: inline-block;
  padding-bottom: 20px;
  width: 80%;
}

.profile-content-main-content-row-icon {
  display: inline-block;
  padding-right: 10px;
  font-size: 27px;
}

.profile-content-main-content-row-text {
  display: inline-block;
  width: 80%;
}

.profile-content-main-content-row-actions {
  width: fit-content;
  display: inline-block;
}

.profile-interest {
  display: inline-block;
  background-color: rgb(255 191 70 / 50%);
  border: 2px solid #ffbf46;
  border-radius: 25px;
  width: 125px;
  color: rgb(169 163 163 / 1);
  margin: 7px;
}

.profile-interest-text {
  display: block;
  font-size: 17px;
  font-weight: bold;
  margin: 14px;
}

.profile-interest-delete-icon {
  padding-left: 6px;
  color: #e17474;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

.actively-shopping-wrapper{

}

.react-date-picker__calendar{
  z-index: 99 !important;
}

.actively-shopping-list{
  
}

.actively-shopping-item{
  background-color: #fefefe;
  width: 95%;
  margin: auto;
  margin-top: 15px;
  border-radius: 25px;
  padding: 15px;
  padding-right: 75px;
  position: relative;
}

.actively-shopping-item-delete-btn{
  width: 60px!important;
  color: #666!important;
  font-size: 14px!important;
  font-style: italic!important;
  font-weight: bold!important;
  position: absolute!important;
  right: 10px!important;
  top: 33%!important;
}

.actively-shopping-selection-save-btn-wrapper{

}

.actively-shopping-selection{
  padding-top: 20px;
}

.actively-shopping-done-prompt-subject {
  text-align: center;
  color: gray;
}

.actively-shopping-done-prompt-header {
  background-color: #648381;
  height: 35px;
  color: white;
  text-align: center;
  width: calc(100% + 40px);
  margin: -20px;
  margin-bottom: 50px;
  font-size: 16px;
}

.actively-shopping-done-prompt-header-text {
  padding-top: 7px;
}

.actively-shopping-done-prompt-text {
  font-size: 18px;
  font-weight: 600;
  color: gray;
  padding: 5px;
}

.actively-shopping-done-prompt-x {
  float: right;
  position: relative;
  right: 10px;
}

.actively-shopping-username{

}

.actively-shopping-selection-wrapper{

}

.activley-shopping-selection{

}

.actively-shopping-selection-save-btn-wrapper{
  padding-top: 12px;
}

.actively-shopping-selection-save-btn{
  background-color: #ffbf46;
  border: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  height: 60px;
  width: 225px;
}

.actively-shopping-preferred-brands-wrapper{
  margin-top: 50px;
  margin-bottom: 50px;
}

.actively-shopping-preferred-brands-header{
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.actively-shopping-preferred-brand{

}

.feedback-nav-option-wrapper {
  padding: 15px;
  margin: auto;
  background-color: #ececec;
  border-radius: 10px;
  height: 60px;
  position: relative;
  width: 100%;
}

.feedback-nav-option {
  display: inline-block;
  width: 33%;
  cursor: pointer;
  color:'gray';
  border-right:'1px solid grey';
}

.feedback-nav-option-active {
  display: inline-block;
  width: 33%;
  cursor: pointer;
  border-right: 1px solid grey;
  background: #fff;
  border: 1px solid #ffbf46;
  border-radius: 6px;
  color: #ffbf46;
}

.brand-feedback-content-wrapper {
  height: 700px;
  background-color: #EEE;
}

.brand-feedback-super-like-btn-wrapper {
  position: absolute;
  margin-left: -25px;
  left:50%;
  margin-top: -20px;
}

.brand-feedback-super-like-btn {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: gray;
  color: white;
  font-size: 22px;
  cursor: pointer;
}

.brand-feedback-content-logo-wrapper {
  height: 50%;
  padding-top: 25%;
}

.purchase-feedback-content-logo-wrapper {
  height: 100%;
}

.add-purchase-details-modal-reason-btn-wrapper {
  width:80%;
  margin: auto;
  position: relative;
  margin-top: 150px;
}

.add-purchase-details-modal-reason-btn-purchase{
  background-color: #ffbf46;
  border: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 75%;
  height: 60px;
  font-weight: bold;
  margin: 3%;
  width: 43%;
}

.add-purchase-details-modal-reason-btn-no-shopping{
  background-color: #909090;
  border: none;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 75%;
  height: 60px;
  font-weight: bold;
  margin: 3%;
  width: 43%;
}

.add-purchase-details-modal-detail-wrapper{

}

.add-purchase-details-modal-detail-header{
  font-size: 24px;
  font-weight: 700;

}

.add-purchase-details-modal-detail-sub-header{

}

.add-purchase-details-modal-close-detail-btn{
  display: none;
}

.add-purchase-details-modal-save-detail-btn{
  position: relative;
  left: 33%;
  width: 33%;
  height: 30px;
  border-radius: 7px;
  border: none;
  background-color: #575761;
  color: white;

}

.brand-feedback-content-logo {
  border-radius: 50px;
  width: 65px;
  height: 65px;
  margin-bottom: 25px;
}

.brand-feedback-content-company-name {
  font-size: 30px;
  font-weight: bold;
}

.social-guid-inner-wrapper h3 {
  width:85%;
}

.facebook-guide-wrapper {
  background-color: #242526;
  border: none;
  border-radius: 15px;
  height: fit-content;
  inset: 10% auto auto 10%;
  position: absolute;
  width: 80%;
  z-index: 99;
}

.facebook-guide-inner{
  height: 100%;
}

.facebook-guide-img {
  width: 100%;
  padding-top: 20px;
}

.close-facebook-instructions {
  cursor: pointer;
  font-size: 35px;
  position: absolute;
  top: 5px;
  right: 10px;
}

.social-guide-info {
  position: relative;
  cursor: pointer;
  inset: 35px auto auto 50px;
  font-size: 16px;
}

.social-guid-inner-wrapper {
  font-size: 16px;
  padding: 14px;
  text-align: left;
  margin-top: 0px;
  margin-left: 10px;
}

.feedback-filter-container {
    display: inline-block;
    position: absolute;
    right: 0px;
}

.feedback-filter-container select {
    height: 50px;
    width: 225px;
    border-radius: 25px;
    padding: 15px;
    border: 1px solid #d3d3d3;
    font-size: 18px;
    color:#999;
}

.form-section {
  height: 55px;
  margin: 14px;
}

.form-section input {
  position: absolute;
  left: 15px;
  width: 90%;
  border: 1px solid #d3d3d3;
  border-radius: 25px;
  color: #999;
  font-size: 18px;
  height: 50px;
  padding-top: 35px;
  padding-left: 20px;
  padding-bottom: 19px;
}

.form-section select {
  position: absolute;
  left: 15px;
  width: 90%;
  border: 1px solid #d3d3d3;
  border-radius: 25px;
  color: #999;
  font-size: 18px;
  height: 50px;
  padding-top: 23px;
  padding-left: 20px;
  padding-bottom: 5px;
}

.form-section .registration-form-input {
  position: absolute;
  left: 33%;
  width: 34%;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
  color: #5d5454;
  font-size: 18px;
  height: 60px;
  padding-top: 20px;
  padding-left: 25px;
  padding-bottom: 5px;
}

.form-section-label {
  padding-right: 5px;
  position: absolute;
  margin-top: 5px;
  z-index: 98;
  left: 35px;
  color: black;
  font-size: 14px;
}

.registration-form-section-label {
  padding-right: 5px;
  position: absolute;
  margin-top: 5px;
  z-index: 98;
  left: 34%;
  color: black;
  font-size: 14px;
}

.registration-form-section-label-icon{
  color: gray;
  position: relative;
  z-index: 99;
  font-size: 20px;
  display: inline-block;
  margin: 15px auto auto 30%;
}

.get-started-page {
  height: 100%;
  color: white;
  overflow: auto;;
}

.get-started-install-list {
  color: rgb(255, 191, 70);
  text-align: left;
  font-weight: bold;
  font-size: 18px;
}

.get-started-install-app-tile {
  width: 33%;
  height: 325px;
  border-radius: 50px;
  background-image: linear-gradient(to bottom right, #8acb88, #648381);
  padding: 10px;
  display: inline-block;
  margin-right: 11%;
}

.get-started-install-app-tile-first {
  width: 33%;
  height: 325px;
  border-radius: 50px;
  background-image: linear-gradient(to bottom right, #8acb88, #648381);
  padding: 10px;
  display: inline-block;
  margin-right: 11%;
  margin-left: 11%;
}

.get-started-banner{
  height: 100px;
  background-image: linear-gradient(to bottom right,#8acb88,#648381);
  text-align: center;
}

.get-started-logo-wrapper{
  display: inline-block;
  vertical-align: text-bottom;
  position: relative;
  top: 15px;
}

.get-started-logo-img-wrapper{
  background: rgba(0, 0, 0, 0.3);
  width: fit-content;
  border-radius: 70px;
  padding: 5px;
  display: inline-block;
}

.get-started-header-logo{
  width: 25px;
  vertical-align: middle;
}

.get-started-header-app-name{
  display: inline-block;
  color: white;
  font-size: 35px;
  font-weight: 600;
  padding-left: 12px;
  position: relative;
  top: 5px;
}

.get-started-title-wrapper{
  color: black;
  margin-bottom: 30px;
}

.get-started-install-brand-icon-wrapper{
  font-size: 50px;
  background-color: rgb(0, 0, 0, 0.3);
  width: 100px;
  position: relative;
  margin: 0 auto;
  border-radius: 90px;
  height: 100px;
  color: white;
}

.get-started-install-instructions-list{
  text-align: left;
  font-size: 18px;
  font-weight: 500;
}

.get-started-preferences-wrapper{
  color: gray;
  margin-top: 60px;
  margin-bottom: 60px;
}

.get-started-preferences-toggle-wrapper{
  background-color: white;
  border-radius: 50px;
  width: 80%;
  margin: 0 auto;
  padding: 30px;
}

.get-started-more-wrapper{
  color: gray;
  width: 80%;
  margin: 0 auto;
}

.get-started-more-section-wrapper{
  width: 28%;
  display: inline-block;
  background-color: white;
  margin-right: 8%;
  padding: 20px;
  border-radius: 50px;
}

.get-started-more-section-wrapper-last{
  width: 28%;
  display: inline-block;
  background-color: white;
  padding: 20px;
  border-radius: 50px;
}

.get-started-preferences-section-wrapper{
  width: 33%;
  display: inline-block;
  padding: 15px;
}

.get-started-preference-item-container {
  margin: 10px;
  margin-bottom: 25px;
  text-align: center;
}

.get-started-toggle-wrapper{
  width: fit-content;
  margin: 0 auto;
  height: 60px;
}

.get-started-more-tile-header{
  font-size: 30px;
  font-weight: 500;
  padding: 15px;
}

.get-started-toggle-description{
  color: rgb(255, 191, 70);
  font-size: 18px;
  font-weight: bold;
  height: 50px;
}

.get-started-learn-more-highlight{
  color: #FFBF46;
  padding-top: 15px;
  padding-bottom: 20px;
  font-size: 22px;
  font-weight: 500;
}

.preference-item-container{
  margin: 10px;
  text-align: left;
  margin-bottom:25px;
}
.preference-item-container .container {
  display: block;
}

.get-started-preference-item-container .container {
  display: block;
}

.toggle-switch {
  display: block!important;
  position: relative;
  text-align: left;
  top: 8px;
  clear: both;
  width: 60px;
}

.social-connections-pending-requests-wrapper{

}

.social-connections-pending-sent-requests-wrapper{

}

.social-connections-wrapper{

}

.social-connection{

}

.social-connection-pending-sent-request{

}

.social-connection-icon{
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: lightgrey;
  color: white;
  padding-top: 15px;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
  position: absolute;
}

.social-connection-icon-share{
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: lightgrey;
  color: white;
  padding-top: 15px;
  font-size: 20px;
  font-weight: 500;
  margin: 10px;
  display: inline-block;
  text-align: center;
}

.social-connection-details-wrapper{
  display: inline-block;
  margin-left: 85px;
  text-align: left;
}

.social-connection-details-wrapper-share{
  display: inline-block;
  text-align: left;
}

.social-connection-pending-request{

}

.social-connection-name{
  font-size: 18px;
  font-weight: bold;
}

.social-connection-pending-actions{
  font-size: 24px;
  text-align: center;
}

.social-connection-list {
  list-style: none;
  padding: 0;
}

.social-connection-list-share {
  list-style: none;
  padding: 0;
}

.social-connection-list-share {
  list-style: none;
  padding: 0;
  text-align: left;
}

.social-connection-list li {
  background-color: #ffffff;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.social-connection-leaderboard-name{
  color: #ffbf46;
}

.social-connection-remove{
  font-size: 24px;
  text-align: center;
}

.connection-details-connection-intials-wrapper{
  display: inline-block;
  width: 75px;
  height: 75px;
  background-color: lightgrey;
  border-radius: 40px;
  text-align: center;
  line-height: 75px;
  font-size: 24px;
  color: white;
  font-weight: 500;
  margin-right: 20px;
}

.connection-details-connection-details-wrapper{
  display: inline-block;
}

.connection-details-favorite-brand-wrapper{
  display: inline-block;
  margin: 5px;
}

.connection-details-favorite-brand-img {
  width: 75px;
  height: 75px;
  border-radius: 40px;
}

.connection-details-interest {
  display: inline-block;
  width: 100px;
  background-color: #ffbf46;
  text-align: center;
  height: 25px;
  color: white;
  line-height: 24px;
  margin: 5px;
}

  @media only screen and (max-width: 1024px) {   
    .registration-content {
      overflow: scroll;
      margin-top: 0;
      padding-bottom: 75px;
    }
    
    .registration-content-inner{
      top: 2%;
      padding-bottom: 3%;
    }

    .registration-form-section-label-icon{
      margin: 15px auto auto 60%;
    }

    .registration-content-header-logo-container {
      display: block;
      width: 83px;
      position: relative;
      left: 0;
      margin: 0 auto;
    }
    
    .registration-content-header-text {
      font-size: 30px;
      left: 0;
      bottom: 0;
      color: #FFBF46;
      font-weight: bold;
    }

    .form-section .registration-form-input {
        left: 15%;
        width: 70%;
    }

    .registration-form-section-label {
      left: 18%;
    }

    .home-page-summary-wrapper {
      margin-left: unset;
      margin-right: unset;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      width:100%;
    }
    .home-page-summary-section {
      margin: auto;
      width:70%;
      margin-bottom: 25px;
    }
    .home-content-body, .home-content-title {left: 75px;}
    .home-content-title {
        font-size: 50px; 
        top: 100px;}
    .home-content-body {
      font-size: 22px;
      top: 170px;
      max-width: 900px;
    }
    .social-continue-btn {left:75px;}
    .home-content-bottom .social-continue-btn {
      left:70px;
  }
    .banner-welcome-text {font-size:26px; padding-top: 45px;}
    .home-content-bottom {bottom: -260px;}
    .no-ads-container {
      color: gray;
      font-size: 35px;
      position: relative;
      top: 15vw;
      width: 90%;
      margin: auto;
      margin-top: 25%;
    }
    .no-ads-text-container {
      padding-bottom: 30px;
      width: max-content;
      margin: auto;
    }
    #not-interested-modal .form-label, #is-interested-modal .form-label{
      font-size:18px!important;
      line-height: 1.5!important;
    }
}
  @media only screen and (max-width: 768px) {
    .feedback-filter-container {right:25px;}
    .feedback-review-table-contaier {font-size:14px;}
    .feedback-review-table td {
      max-width:125px;
    }
    .replacement-text {
      left:unset;
    }
    .ad-feedback-business-category {
        font-size: 10px;
        font-weight: 500;}
    .home-content-body {
      font-size: 18px;
      top: 200px;
    }
    .home-content-title {
      font-size: 32px;
      top: 155px;
    }
    .home-content-bottom {
      bottom: -275px;
    }

    .feedback-filter-container {
        right: 25px;}
    .feedback-filter-container select {
        width: 180px;
        font-size: 18px;}
    .banner-welcome-text {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        padding-left: 100px;
        padding-top: 50px;
        position: relative;
        text-align: left;
        z-index: 3;}
    .home-summary-bubble-first {
        left: 90px;
        top: 110px;}
    .home-summary-bubble-second {
        left: 230px;
        top: 110px;}
    .home-summary-bubble-third {
        left: 370px;
        top: 110px;}
    .no-ads-container {
      color: gray;
      font-size: 35px;
      position: relative;
      top: 15vw;
      width: 90%;
      margin: auto;
      margin-top: 25%;
    }
    .no-ads-text-container {
      padding-bottom: 30px;
      width: max-content;
      margin: auto;
    }

    .get-started-install-app-tile {
      width: 85%;
      margin: 0 auto;
      display: block;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    
    .get-started-install-app-tile-first {
      width: 85%;
      margin: 0 auto;
      display: block;
      margin-bottom: 50px;
      margin-top: 50px;
    }

    .get-started-preferences-toggle-wrapper{
      width: 85%;
    }

    .get-started-preferences-section-wrapper{
      width: 100%;
      display: block;
    }
    
    .get-started-more-wrapper{
      width: 100%;
    }
    
    .get-started-more-section-wrapper{
      width: 85%;
      display: block;
      margin: 0 auto;
      margin-bottom: 50px;
    }

    .get-started-more-section-wrapper-last{
      width: 85%;
      display: block;
      margin: 0 auto;
      margin-bottom: 50px;
    }

    .get-started-learn-more-highlight{
      font-size: 18px;
    }
}

 @media only screen and (max-width: 640px) {

  .App-header {
  background-image: linear-gradient(to bottom right,#8acb88,#648381);
  color: #fff;
  font-size: calc(10px + 2vmin);
  height: 80px;
  min-height:80px;
  width: 100%;
  z-index: 99;
  position: fixed;
  bottom: 0;
  left: 0;
}
.brand-feedback-super-like-btn-wrapper {
  top: 30px;
}
.icon-container-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 30px;
  position: absolute;
  top: -20px;
}
.App-header .icon-container-svg {
  padding-top: unset;
  width: 20px;
  position: absolute;
  left: 10px;
  top: 5px;
}
.App-header .icon-container img, .App-header .icon-container-active img {
  top: 10px;
}
.App-header .icon-container, .App-header .icon-container-active {
  position:relative;
}
.home-page {
  height: unset;
  position: relative;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 80px;
  background-position-x: -160px;
  padding-bottom:90px;
}
.carousel .control-dots {
  margin:0px!important;
}
.feedback-review-table-contaier, .feedback-review-table-header {
  width: 100%;
}
.feedback-review-table-header{
  padding-top: 25px;
}
.feedback-nav-option-wrapper {
  width:95%;
}

.feedbacks-page{
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 80px;
  /*margin-bottom: unset;*/
}
.feedbacks-page-ads {
  display:block;
}
.icon-container-main .white-text {
  width:85px;
  display: block;
} 
.home-summary-bubble-first {
  left: 20px;
}
.home-summary-bubble-second {
  left: 155px;
}
.home-summary-bubble-third {
  left: 285px;
}
.home-content-body {
  font-size: 14px;
  top: 205px;
}
.home-content-title {
  font-size: 24px;
  top: 170px;
}
.home-content-body, .home-content-title {
    left: 40px;
}
.feedback-review-table-header span {
  position: absolute;
  left: 25px;
  font-size: 18px;
  padding-top: 15px;
}
.home-content-bottom {
  bottom: -270px;
}
.home-content-bottom .social-continue-btn {
  font-size: 14px;
  left: unset;
  position: relative;
  width: 50%;
  margin: auto;
}
.profile-content-main-wrapper {
  width:100%;
}
.profile-button {
  position: relative;
}
.profile-button .svg-inline--fa {
  height: 15px;
  vertical-align: unset;
  position: absolute;
  left: 11px;
  top: 8px;
}
.profile-button-container {
  right: 10px;
  left: unset;
  width: 40px;
  bottom: 35px;
}
.home-content-wrapper {
  height: 360px;
  left: unset;
  overflow: hidden;
  position: relative;
  width: 90%;
  margin: auto;
}
.category-wrapper {
  padding-left: 0px;
  position: relative;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 80px;
}
.business-category-search-wrapper {
  width: 90%;
}
.business-category-search-bar {
  width: 70%;
}
.add-business-btn-wrapper {
  margin: auto;
  position: relative;
  width: 90%;
  height: 120px;
}
.add-brand-btn {
  background-color: #8acb88;
  border: none;
  border-radius: 90px;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  height: 70px;
  margin-left: unset;
  position: unset;
  width: 70px;
  display: inline;
  clear: both;
}
.add-business-btn-wrapper span {
  font-size: 12px;
  margin-left: unset;
  position: unset;
  display: block;
  clear: both;
  width: 100%;
  margin-top: 10px;
}
#search-brand-modal, #add-purchase-details-modal {
  width:90%!important;
  height:575px!important;
  inset: 40% auto auto 50%!important;
}
#search-brand-modal .cat-search-btn {
  width:25%;
}

#search-brand-modal .bus-search-btn {
  width:30%;
  left: 70%
}

#search-brand-modal .business-category-search-bar {font-size: 14px;}
.cat-search-btn {
  left: 70%;
  width:30%;
}

.bus-search-btn {
  left: 75%;
  width:30%;
  text-align: center;
}
.business-modal-search-bar {
  width:75%;
}
.qr-reader-btn {
  left:86%;
}

.category-section-wrapper {
  width: 90%;
}
.category-section-tile-wrapper {
  margin-right: 0px;
  width:100%;
}
.category-section-wrapper {
  width: 90%;
}
.banner-welcome-text {
  padding-left: 25px;
}
.profile-content-wrapper {
  width:100%;
  margin:auto;
}
#share-ad-modal {
  width: 90% !important;
  margin-top: 50px !important;
}
#share-ad-modal .form-section input {
  width:90%!important;
}
.profile-page-wrapper {

}

.profile-cotent-top-content-name {
  font-size: 28px;
}
.profile-content-top{
  height:125px;
}
.profile-content-top-img{
  font-size:75px;
}
.profile-popup-container {
  background: #fff;
  height: 500px;
  position: absolute;
  width: 325px;
  z-index: 99;
  bottom: 45px;
  left:unset;
  right: -10px;
}
.overlay-feedback-btn-arrow {display:none;}
.overlay-feedback-btn-arrows-wrapper {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 99;
}
.overlay-feedback-btn-arrows-wrapper .overlay-feedback-btn-arrow {
  color: #6f6f6f82;
  left: 15px;
  top: 15px;
  display: block;
}
.overlay-feedback-btn-arrows-wrapper .overlay-feedback-btn-arrow-right {
  right: 25px;
  position: absolute;
  left: unset;
}
.ad-does-not-apply-wrapper {
  bottom: 15px;
}
.no-ads-container {
  color: gray;
  font-size: 35px;
  position: relative;
  top: 15vw;
  width: 90%;
  margin: auto;
  margin-top: 25%;
}
.no-ads-text-container {
  padding-bottom: 30px;
  width: max-content;
  margin: auto;
}
.ads-page .non-home-page-banner-container{
  display:none;
}
.ads-page .ad-page-sub-banner{
  display:none;
}
.non-home-page-banner-container{
  display:none;
}
.ad-page-sub-banner{
  display:none;
}
.ads-page .ad-main-container {
  width:100%;
}
.ads-page .ad-tile-overlay {
  width:100%;
  border-radius: 0px;
}
.overlay-feedback-btn, .overlay-feedback-btn-interested {
  font-size: 13px;
  height: 60px;
  width: 120px;
  top:-10px;
}
.overlay-feedback-middle {
  font-size: 18px;
  height: 60px;
  margin-left: 20px;
  margin-right: 20px;
  top: 0px;
  width: 60px;
}
.ad-preferred-brand-wrapper, .ad-favorite-brand-wrapper, .actively-shopping-indicator-text {
  font-size: 16px;
}
.brand-feedback-super-like-btn .fa-share {
  position: absolute;
  left:15px;
  top:15px;
}
.brand-feedback-super-like-btn .fa-heart {
  position: absolute;
  left:15px;
  top:15px;
}
.ad-wrapper-container {
  align-items: center;
  display: flex;
  padding-top:0px;
  height: unset;
  justify-content: unset;
  margin: 0px;
  position: absolute;
  top: 0px;
  bottom: 80px;
  left: 0px;
  right: 0px;
}
.ad-wrapper {
  height: unset;
  width: unset;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
#not-interested-modal, #is-interested-modal, #provide-feedback-modal{
  position: absolute!important;
  inset: 55% auto auto 50%!important;
  transform: translate(-50%, -75%)!important;
  width: 90%!important;
  margin-top:0px!important;
}
#not-interested-modal .InputField, #is-interested-modal .InputField{
  padding-top:5px;
}
 }


 .footer {
  color: black;
  position: absolute;
  font-size: 15px;
  width: 100%;
  z-index: 98;
  bottom:0;
}

@media only screen and (max-width: 400px) {
  .home-summary-bubble {
    height: 100px;
    position: absolute;
    width: 100px;
  }
  .invite-friends-social-btn-label{
    font-size: 18px;
  }
  .home-summary-bubble-first {
    left: 15px;
  }
  .home-summary-bubble-second {
    left: 145px;
  }
  .home-summary-bubble-third {
    left: 280px;
  }
  .home-summary-bubble-content {
    padding-top: 16px;
    font-size: 34px!important;
  }
  .home-summary-bubble div {
    font-size: 10px;
  }
  .icon-container-main .white-text {
    width: 80px;
  }
}

@media only screen and (max-width: 360px) {
  .home-summary-bubble {
    height: 95px;
    position: absolute;
    width: 95px;
  }
  .home-summary-bubble-first {
    left: 10px;
  }
  .home-summary-bubble-second {
    left: 130px;
  }
  .home-summary-bubble-third {
    left: 250px;
  }
  .home-content-body, .home-content-title {
    left: 20px;
  }
  .icon-container-main .white-text {
    width: 70px;
  }
  .feedback-review-table-header span {
    font-size: 16px;
    left: 15px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
