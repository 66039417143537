.landing-page-wrapper {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right,#8acb88,#648381);
    overflow: auto;
  }

.parsal-yellow {
    color: #ffbf46
}

.landing-page-header-wrapper {
    padding: 20px;
    padding-left:50px;
}

.landing-page-header-logo{
    width: 25px;
    vertical-align: middle;
}

.landing-page-header-logo-wrapper{
    display: inline-block;
    width: 33%;
    text-align: left;    
    vertical-align: text-bottom;
}

.landing-page-header-logo-img-wrapper{
    background: rgba(0, 0, 0, 0.3);
    width: fit-content;
    border-radius: 70px;
    padding: 5px;
    display: inline-block;
}

.landing-page-header-app-name{
    display: inline-block;
    color: white;
    font-size: 35px;
    font-weight: 600;
    padding-left: 12px;
    position: relative;
    top: 5px;
}

.landing-page-header-navigation{
    display: inline-block;
}

.landing-page-header-nav-links{
    display: inline-block;
    width: 65%;
    text-align: right;
}

.landing-page-header-nav-link:hover{
    color: #ffbf46;
}

.landing-page-header-nav-link{
    display: inline-block;
    font-size: 24px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 30px;
}

.landing-page-header-nav-link-active{
    display: inline-block;
    font-size: 24px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 5px;
    padding-right: 30px;
    padding-left: 30px;
}

.landing-page-header-nav-link-leaderboard{
    display: inline-block;
}

.landing-page-header-nav-link-about{
    display: inline-block;
}

.landing-page-header-nav-link-how-it-works{
    display: inline-block;
}

.landing-page-header-nav-link-leaderboard{
    display: inline-block;
}

.landing-page-header-navigation-profile{
    font-size: 35px;
    color: white;
    cursor: pointer;
    padding-left: 20px;
}

.landing-page-header-navigation-profile:hover {
    color: #ffbf46;
}

.landing-page-header-navigation-profile-login{

}

.landing-page-content-wrapper{
    color: white;
    background-image: linear-gradient(to right,#8acb88,#648381);
}

.landing-page-content-area-one{
    width: 45%;
    text-align: left;
    position: absolute;
    top: 25%;
    left: 5%;
}

.landing-page-content-area-one-wrapper{
    width: 50%;
    display: inline-block;
}

.landing-page-content-area-one-welcome-wrapper{
    font-size: 40px;
}

.landing-page-content-area-one-welcome-sub-text{
    font-weight: bold;
}

.landing-page-content-area-one-description-wrapper{
    font-size: 22px;
    padding: 15px;
    padding-left: 0;
}

.landing-page-content-area-one-sign-up-wrapper{
    font-size: 22px;
}

.landing-page-content-area-one-sign-up-description{

}

.landing-page-content-area-one-sign-up{
    padding-top: 25px;
}

.landing-page-content-area-two{
    display: inline-block;
    width: 45%;
    padding-top: 125px;
    padding-right: 2%;
}

.landing-page-content-area-two-carousel-wrapper{

}

.landing-page-footer-wrapper{
    height: 100px;
    background-color: rgba(4, 4, 4, .4);
    background-image: url(https://img.freepik.com/premium-vector/dark-abstract-background-with-hexagon-pattern-style-seamless-concept_7505-2041.jpg);
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    font-size: 24px;
}

.carousel .slide img {
    width: auto;
}

.landing-page-footer-wrapper::before{
    background-color: rgba(0, 0, 0, .75);
    bottom: 0;
    content: "";
    height: 100px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.landing-page-sign-up-btn {
    width: 30%;
    height: 60px;
    border-radius: 10px;
    background-color: #575761;
    border: none;
    color: white;
    font-size: 22px;
    cursor: pointer;
}

.landing-page-sign-up-btn:hover {
    background-color: #648381;
}

.landing-page-sign-up-email{
    width: 50%;
    height: 60px;
    border: none;
    border-radius: 10px;
    font-size: 24px;
    padding-left: 14px;
}

.landing-page-carousel-description{
    padding-top: 22px;
    font-size: 28px;
    display: inline-block;
    margin-bottom: 40px;
}

.spacer{
    width: 15px;
    display: inline-block;
}

.footer-links{
    padding-top: 30px;
    position: relative;
    z-index: 99;
    color: white;
}

.footer-link{
    display: inline-block;
    font-size: 20px;
    padding-right: 15px;
    padding-left: 15px;
    border-right: white 2px solid;
    cursor: pointer;
}

.footer-link:hover{
    color: #ffbf46;
}

.footer-link-right{
    border-right: none;
}

.landing-page-header-hamburger-menu {
    display: none;
}
.menu-item {
    cursor: pointer;
}
.landing-page-carousel-img{
    width: 20% !important;
}

.landing-page-leaderboard-banner-wrapper{
    height: fit-content;
    background: white;
    text-align: left;
    color: gray;
}

.landing-page-leaderboard-banner-content{
    width: 88%;
    margin-left: 7%;
    margin-right: 5%;
    padding-top: 35px;
    padding-bottom: 35px;
}

.landing-page-leaderboard-banner-top{
    font-size: 38px;
}

.leaderboard-banner-top-text{
    margin: 0;
}

.leaderboard-banner-top-text-bold {
    font-weight: bold;
    margin: 0;
    margin-top: -10px;
    margin-bottom: 10px;
}

.landing-page-leaderboard-banner-middle{
    font-size: 22px;
}

.landing-page-leaderboard-banner-bottom{
    font-size: 24px;
    padding-top: 14px;
    font-weight: 500;
}

.landing-page-leaderboard-ranking-wrapper{
    color: white;
    height: calc(49% - 100px);
}

.landing-page-leaderboard-ranking-content{
    height: fit-content;
    padding-bottom: 75px;
}

.landing-page-leaderboard-rank-section-wrapper{
    display: inline-block;
    height: 100%;
    padding: 35px;
}

.leaderboard-rank-title{
    font-size: 24px;
    font-weight: bold;
}

.leaderboard-rank-section{
    background-color: rgb(0, 0, 0, 0.2);
    padding: 35px;
    border-radius: 40px;
    height: 100%;
}

.leaderboard-rank-tile-second{
    display: inline-block;
    background-color: rgb(0, 0, 0, 0.2);
    border-radius: 12px;
    height: 170px;
    width: 115px;
}

.leaderboard-rank-tile-icon-wrapper{

}

.leaderboard-rank-tile-icon{
    font-size: 50px;
}

.leaderboard-rank-tile-icon-first{
    font-size: 65px;
}

.leaderboard-rank-tile-name{
    width: 50%;
    overflow-wrap: break-word;
    position: relative;
    left: 25%;
}

.leaderboard-rank-position-bubble-first {
    background-color: #575761;
    color: white;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    border-radius: 40px;
    line-height: 50px;
    text-align: center;
    position: relative;
    bottom: 0;
    font-weight: bold;
}

.leaderboard-rank-position-bubble{
    position: relative;
    bottom: 0;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background: white;
    color: black;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
}

.leaderboard-rank-tile-first{
    display: inline-block;
    background-color: rgb(0, 0, 0, 0.2);
    padding-bottom: 15px;
    border-radius: 12px;
    margin-right: 10px;
    margin-left: 10px;
    height: 210px;
    width: 115px;
}

.leaderboard-rank-tile-icon-wrapper{
    height: 150px;
}

.leaderboard-rank-tile-icon-wrapper-first{
    height: 175px;
}

.leaderboard-rank-tile-icon{

}

.leaderboard-rank-tile-name{

}

.landing-about-content-wrapper{
    display: flex;
    height: fit-content;
}

.landing-about-content-text-wrapper{
    color: gray;
    text-align: left;
    flex: 0.6;
    background-color: white;
    display: flex;
}

.landing-about-content-text-header{
    font-size: 42px;
    margin-bottom: 0;
}

.landing-about-content-text-header-bold{
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 42px;
    font-weight: bold;
}

.landing-about-content-text{
    padding-left: 10%;
    padding-top: 5%;
    padding-right: 15px;
    font-size: 20px;
}

.landing-about-content-image-wrapper{
    flex: 0.4;
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: right;
}

.landing-about-img{
    max-width: 100%;
    max-height: 100%;
}

.landing-page-leaderboard-banner-arrow-wrapper{
    text-align: right;
    font-size: 30px;
}

.landing-about-content-image-wrapper-mobile{
    display: none;
}

.landing-about-blog-content-wrapper {
    margin-bottom: 100px;
    background-color: #f2f2f2;
    padding-bottom: 100px;
    padding-top: 50px;
}

.landing-about-blog-content-title{
    font-size: 40px;
    font-weight: 700;
    color: gray;
    padding: 25px;
    padding-bottom: 0;
}

.landing-about-blog-article-title{
    font-size: 30px;
    font-weight: 500;
    color: gray;
    padding: 25px;
    padding-bottom: 0px;
}

.landing-about-blog-article {
    padding: 20px;
    padding-left: 15%;
    padding-right: 15%;
}

.landing-about-blog-back-btn {
    font-size: 28px;
    color: gray;
    font-weight: bold;
    text-align: left;
    position: relative;
    top: 25px;
}

.landing-about-blog-back-text{
    font-size: 22px;
    padding-left: 12px;
}

.landing-about-blog-article-author{
    font-size: 18px;
}

a:visited{
    color: black !important;
}

.landing-about-blog-article-body{
    text-align: left;
    font-weight: 500;
    font-size: 20px;
}

.landing-how-it-works-content-header{
    font-size: 42px;
    color: gray;
    padding: 30px;
    font-weight: 500;
}

.landing-how-it-works-item{
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 300px;
}

.landing-how-it-works-content-wrapper{
    background-color: white;
    padding-bottom: 50px;
    overflow: scroll;
    height: calc(100% - 145px);
}

.landing-how-it-works-item-video-thumb{
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.landing-how-it-works-item-text{
    width: 50%;
    height: 100%;
    padding: 15px;
    text-align: left;
    display: inline-block;
    color: gray;
    vertical-align: middle;
    height: 100%;
    padding-left: 35px;
}

.landing-how-it-works-item-text-header{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}

@media only screen and (max-width: 1330px) {
    .landing-page-header-nav-link{
        font-size: 18px;
    }

    .landing-page-header-nav-link-active{
        font-size: 18px;
    }
}

@media only screen and (max-width: 1200px) {
    .landing-page-header-hamburger-menu {
        display: inline-block;
        padding: 25px;
        position: relative;
        top: -5px;
    }

    .landing-page-header-logo{
        width: 26px;
    }

    .landing-page-header-nav-link{
        display: none;
    }
    
    .landing-page-header-nav-link-active{
        display: none;
    }

    .landing-page-header-app-name{
        font-size: 30px;
        position: relative;
        top: 4px;
    }

    .landing-page-header-navigation-profile{
        font-size: 36px;
    }

    .landing-page-carousel-img{
        width: 40% !important;
    }

    .landing-page-wrapper {
        background-image: none;
        background-color: #729E83;
        overflow: auto;
    }

    .landing-page-header-wrapper {
        position: fixed;
        top: 0;
        width: 100%;
        padding: 10px;
        padding-right:30px;
        z-index: 99;
        background-color: #729E83;
    }

    .landing-page-header-logo-wrapper{
        display: inline-block;
        width: 48%;
        text-align: left;
    }
    
    .landing-page-header-nav-links{
        display: inline-block;
        width: 48%;
        text-align: right;
    }

    .landing-page-content-wrapper{
        background-image: none;
        height: 100%;
        overflow: scroll;
        padding-bottom: 125px;
    }

    .landing-page-content-area-one-wrapper{
        position: relative;
        left: 5%;
        top: 575px;
        width: 90%;
    }
    
    .landing-page-content-area-one{
        width: 90%;
        text-align: left;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
    }

    .landing-page-content-area-one-welcome-wrapper{
        font-size: 24px;
    }

    .landing-page-content-area-one-description-wrapper{
        font-size: 16px;
        padding: 15px;
        padding-left: 15px;
        padding-left: 0;
    }

    .landing-page-content-area-one-sign-up-wrapper{
        font-size: 16px;
    }

    .landing-page-sign-up-btn{
        width: 100%;
    }

    .landing-page-content-area-two{
        width: 400px;
        top: -100px;
        padding-right: 0;
        padding-top: 0;
        position: relative;
    }

    .landing-page-leaderboard-banner-wrapper{
        margin-top: 85px;
    }

    .landing-page-carousel-description{
        font-size: 16px;
    }

    .leaderboard-rank-tile-first{
        width: 115px;
    }

    .leaderboard-rank-tile-second{
        width: 115px;
    }

    
    .landing-about-content-wrapper{
        display: block;
        height: fit-content;
        margin-bottom: 0;
    }

    .landing-about-content-image-wrapper-mobile{
        display: block;
        position: relative;
        top: 85px;
    }

    .landing-about-content-image-wrapper{
        display: none;
    }

    .landing-about-content-text-wrapper{
        display: block;
        margin-top: 80px;
        padding-bottom: 50px;
    }

    .landing-about-blog-content-wrapper{
        padding-top: 0;
    }

    .landing-how-it-works-content-wrapper{
        position: relative;
        top: 85px;
    }

    .landing-page-footer-wrapper{
        height: 75px;
    }

    .footer-links{
        padding-top: 20px;
    }

    .footer-link{
        font-size: 16px;
    }

   }

   @media only screen and (max-width: 1200px) {

   }

   @media only screen and (max-width: 900px) {
    .landing-how-it-works-item{
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 75%;
    }
    
    .landing-how-it-works-item-video-thumb{
        width: 100%;
        display: block;
        vertical-align: middle;
        height: 60%;
    }
    
    .landing-how-it-works-item-text{
        width: 100%;
        height: fit-content;
        padding: 15px;
        text-align: left;
        display: block;
        color: gray;
        vertical-align: middle;
        padding-top: 0;
    }

    .landing-how-it-works-items-wrapper{
        
    }

    .landing-how-it-works-item-text-header{
        margin-top: 5px;
    }

    .landing-how-it-works-item-text-content{
        margin-top: 5px;
    }
   }

   @media only screen and (max-width: 750px){

   }

   @media only screen and (max-width: 550px){
    .landing-page-content-area-two{
        top: -150px;
    }

    .leaderboard-rank-tile-second {
        width: 75px;
    }

    .leaderboard-rank-tile-first{
        width: 75px;
    }

    .leaderboard-rank-section {
        font-size: 12px;
    }
   }

   @media only screen and (max-width: 400px){
    .landing-page-content-area-two{
        top: -200px;
        width: 300px;
    }

    .leaderboard-rank-tile-second {
        width: 50px;
    }

    .leaderboard-rank-tile-first {
        width: 50px;
    }

    .leaderboard-rank-section {
        font-size: 10px;
    }

    .leaderboard-rank-tile-icon{
        font-size: 35px;
    }

    .leaderboard-rank-tile-icon-first{
        font-size: 40px;
    }

   }